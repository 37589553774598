/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/pace-js@1.0.2/pace.min.js
 * - /npm/pjax@0.2.8/pjax.min.js
 * - /npm/whatwg-fetch@3.4.0/dist/fetch.umd.min.js
 * - /npm/animejs@3.2.0/lib/anime.min.js
 * - /npm/algoliasearch@4.24.0/dist/algoliasearch-lite.umd.js
 * - /npm/instantsearch.js@4.77.3/dist/instantsearch.production.min.js
 * - /npm/lozad@1.16.0/dist/lozad.min.js
 * - /npm/quicklink@2.3.0/dist/quicklink.umd.js
 * - /gh/disjukr/activate-power-mode@master/dist/activate-power-mode.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
